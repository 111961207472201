@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #dbeafe;
  --foreground: #ededed;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #dbeafe;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
